import React from "react"
import CharityIcon from "mdi-react/CharityIcon"

import StoreIcon from "mdi-react/StoreIcon"
  

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import transportationForSeniors from "../../images/components/transportation-for-seniors.jpg"
import transportationForSeniors1 from "../../images/components/transportation-for-seniors-1.jpg"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./transportation-for-seniors-alliance.scss"

var TransportationForSeniorsAlliance = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="transportation-for-seniors-alliance-page">
      <Banner
        src={transportationForSeniors}
        title="Transportation for Seniors Alliance"
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="center"
      />
      <PageBody className="page">
        <BorderTitle>
          Transportation for Seniors Alliance
        </BorderTitle>
        <div className="body">
          <img className="left" src={transportationForSeniors1} alt="" /><p>A task force of community members and leaders in Jacksonville to identify and pursue low- or no-cost transportation options for seniors in Health Zone.</p>
        </div>
        
        <div className="navigation">
        <ComponentNavigator
            link="/components-year-two/corner-store-initiative"
            className="first"
            icon={StoreIcon}
            title="Corner-Store Initiative"
            />
          <ComponentNavigator
            next
            link="/components-year-two/jacksonville-urban-league-partnership-and-associated-programs"
            icon={CharityIcon}
            title="Jacksonville Urban League Partnership and Associated Programs"
          />
        </div>
      </PageBody>
    </div>
  </>
  )

export default TransportationForSeniorsAlliance
